import EmberRouter from '@ember/routing/router';
import config from 'dummy/config/environment';
import CommonsRouter from '@trovedata/sunstone-ui-commons/router';
import ScenarioPlannerRouter from '@trovedata/trove-scenario-planner/router';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function() {
  CommonsRouter(this);
  ScenarioPlannerRouter(this, { path: '/' });
});
