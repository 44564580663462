export default {
  time: {
    default: {
      hour: 'numeric',
      minute: '2-digit'
    }
  },
  date: {
    datetime_default: {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    },
    datetime_short: {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    },
    datetime_long: {
      month: 'long',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    },
    'datetime_MM/DD, HH:mm': {
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    },
    date_default: {
      month: 'short',
      day: '2-digit',
      year: 'numeric'
    },
    date_short: {
      month: 'numeric',
      day: '2-digit',
      year: '2-digit'
    },
    date_long: {
      month: 'long',
      day: '2-digit',
      year: 'numeric'
    }
  }
};
